<template>
  <div class="plan-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="$l.get('plan-form.name-field', 'capitalize')"
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="plan.name"
        />
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("plan-form.source-type-field") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              v-model="plan.sourceType"
              vs-name="sourceType"
              vs-value="1"
              >{{ $l.get("dictionary.plan-source-type.dna-skills") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.sourceType"
              vs-name="sourceType"
              vs-value="2"
              >{{ $l.get("dictionary.plan-source-type.slots") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.sourceType"
              vs-name="sourceType"
              vs-value="3"
              >{{ $l.get("dictionary.plan-source-type.researchers") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.sourceType"
              vs-name="sourceType"
              vs-value="4"
              >{{
                $l.get("dictionary.plan-source-type.health-professionals")
              }}</vs-radio
            >
          </li>
        </ul>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("plan-form.payment-type-field") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              v-model="plan.paymentType"
              vs-name="paymentType"
              vs-value="1"
              >{{ $l.get("dictionary.plan-payment-type.free") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.paymentType"
              vs-name="paymentType"
              vs-value="2"
              >{{ $l.get("dictionary.plan-payment-type.raw-data") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.paymentType"
              vs-name="paymentType"
              vs-value="3"
              >{{ $l.get("dictionary.plan-payment-type.monthly") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.paymentType"
              vs-name="paymentType"
              vs-value="4"
              >{{ $l.get("dictionary.plan-payment-type.yearly") }}</vs-radio
            >
          </li>
          <li>
            <vs-radio
              v-model="plan.paymentType"
              vs-name="paymentType"
              vs-value="5"
              >{{ $l.get("dictionary.plan-payment-type.single") }}</vs-radio
            >
          </li>
        </ul>
      </vs-card>
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">
        {{ $l.get("dictionary.submit-new") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
const RE_RSID = /^([Ii]|[Rr][Ss])\d+$/i;

export default {
  name: "plan-form",
  props: ["plan", "onSubmit"],
  data() {
    return {
      isNameInvalid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isNameInvalid = this.plan.name.length == 0;
      if (this.isNameInvalid) {
        return;
      } else {
        this.onSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
.plan-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.plan-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.plan-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.plan-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.plan-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .plan-form .vs-input {
//   margin: 15px;
// }
</style>
